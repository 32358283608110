import React from 'react';

function SignUpPage() {
  return (
    <div className="sign-up-page">
      <h1>Sign Up for Grounded Affirmations</h1>
      {/* Add your sign-up form here */}
    </div>
  );
}

export default SignUpPage;